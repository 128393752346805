@use "styles/shared" as *;

.boxcell {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $accent;
  padding: 8px;
  border-radius: $borderRadius;

  &__fullwidth {
    @include tablet {
      grid-column: span 2;
    }
  }

  &__label {
    font-weight: 900;
  }

  &__cell {
    font-weight: 600;
  }
}
