@use "styles/shared" as *;

.formCheckbox {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  gap: $gap;
  width: 100%;

  & > span {
    width: fit-content;
    margin: 0;
  }

  & > input {
    width: 18px;
    height: 18px;
  }
}
