html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}
body {
  display: flex;
  align-items: stretch;
  margin: 0;
  min-height: 100vh;
}
#root {
  width: 100%;
  height: auto;
  min-height: 100%;
}
main {
  display: block;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
a {
  background-color: transparent;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
details {
  display: block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none;
}
a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
li,
ul {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
  font-family: "Urbanist", sans-serif;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
}

.back-btn-container {
  /* position: absolute; */
  background-color: transparent;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px 6px 0 6px;
  transition: all 0.1s ease-in;
}

.back-btn-container-reportPage {
  justify-content: flex-start;
}

.back-btn-container .back-btn {
  transition: all 0.1s ease-in;
}
.member-back-btn-span:hover .back-btn {
  color: #222222;
}

.member-back-btn {
  justify-content: flex-start;
  padding: 0;
}

.member-back-btn-span:hover,
.back-btn-container-reportPage span:hover {
  background-color: #fff;
}
.back-btn-container-reportPage span:hover {
  color: #000;
}

.member-back-btn-span,
.back-btn-container-reportPage span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 6px 6px 0 6px;
}

.individual-member-back-btn-container {
  display: flex;
  gap: 10px;
}
.report-back-btn,
.individual-member-back-btn {
  position: static;
  padding: 0 10px;
  font-size: 20px;
  background-color: #1b96d1;
  color: #fff;
}
.report-back-btn:hover,
.individual-member-back-btn:hover {
  background-color: #1b96d1;
}

.report-back-btn:hover .back-btn,
.individual-member-back-btn:hover .back-btn {
  color: #fff;
}

.dropDown-pdf-csv {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px 0;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  border: none;
  border-radius: 6px 6px 0 6px;
  cursor: pointer;
  /* transition-duration: 0.6s; */
  gap: 4px;
  color: #f1f5f6;
  background-color: #1b96d1;
  right: 0;
}

.downloadCSV {
  top: 50px;
  background-color: transparent;
  border: none;
}

.csvLink {
  color: #f1f5f6;
  background-color: #1b96d1;
  border: none;
  font-size: 16px;
  font-weight: bold;
}

.select-icon {
  display: flex;
  padding: 0 20px;
}
