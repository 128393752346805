@use "styles/shared" as *;

.notificationToast {
  position: relative;
  background-color: $light;
  color: $black;
  width: 250px;
  padding: 10px 35px 10px 20px;
  border-radius: $borderRadius;
  box-shadow: 0 0 5px 5px rgba($shade, 0.5);

  span {
    display: block;
    padding-left: 18px;
    line-height: 18px;
    margin-top: 2px;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 8px;

    &:nth-child(3) {
      margin-bottom: 10px;
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  @include laptop {
    width: 400px;
  }

  &__close {
    position: absolute;
    top: 20px;
    transform: translate(0, -50%);
    right: 10px;
    cursor: pointer;
  }

  &__info {
    position: absolute;
    top: 20px;
    transform: translate(0, -50%);
    left: 10px;
  }
}

.success {
  .notificationToast__icon,
  > span:nth-child(2) {
    color: $primary;
  }
}

.error {
  .notificationToast__icon,
  > span:nth-child(2) {
    color: $warning;
  }
}
