.ReactModalPortal {
  z-index: 999;
}
.ReactModalPortal > .ReactModal__Overlay {
  z-index: 999;
}
.ReactModal__Overlay > div {
  z-index: 999;
}

.password-check-container {
  width: 80%;
  position: absolute;
  left: 100%;
  top: 0;

  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 4px;
  gap: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.password-check {
  display: flex;
  align-items: center;
  gap: 10px;
}

.titick {
  display: none;
  stroke: green;
}
.cross {
  display: block;
  stroke: white;
  padding: 5px;
}

.w-full {
  width: 100%;
}

.btn_updateReport {
  position: absolute;
  top: 0;
  right: 400px;
}
