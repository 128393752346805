// COLORS
$white: #ffffff;
$black: #222222;
$default: #999999;

$light: #f1f5f6;
$accent: #cecece;
$primary: #1b96d1;
$success: #7fac42;
$warning: #f44336;
$shade: #333333;

// BREAKPOINTS
$tablet: 768px;
$tablet-large: 1020px;
$laptop: 1200px;
$desktop: 1600px;

// STRUCTURE
$borderRadius: 6px 6px 0 6px;
$gap: 15px;
