@use "styles/shared" as *;

.iconLabel {
  width: 100%;
  display: flex;
  align-items: center;
  gap: $gap;

  &__icon {
    font-size: 24px;
    line-height: 24px;
    background-color: $primary;
    padding: 12px 10px 8px 10px;
    border-radius: $borderRadius;
    color: $white;
  }

  &__title {
    display: flex;
    flex-direction: column;
    gap: 3px;
    font-size: 12px;
    font-weight: 500;

    span:first-child {
      font-weight: 900;
      font-size: 16px;
    }
  }
}
