@use "styles/shared" as *;

.layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: $light;

  &__main {
    width: 100%;
    height: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;

    * {
      font-family: inherit;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-columns: auto;
    padding: 20px;
    width: 100%;
    gap: $gap;

    @include tablet {
      grid-template-columns: 300px 1fr;
    }

    @include laptop {
      grid-template-columns: 400px 1fr;
    }
  }

  &__main {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-columns: auto;
    padding: 20px;
    width: 100%;
    gap: $gap;
  }

  &__sidebar {
    border-radius: $borderRadius;
    height: fit-content;

    @include laptop {
      position: sticky;
      top: 80px;
    }
  }

  &__grid {
    display: flex;
    flex-direction: column;
    gap: $gap;
  }
}
