@use "styles/shared" as *;

.formWrap {
  display: flex;
  flex-direction: column;
  background: $shade;
  padding: 16px 10px;
  border-radius: $borderRadius;
  margin-bottom: $gap;
  width: 100%;

  &:hover {
    box-shadow: 0 0 5px 5px rgba($shade, 0.1);
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $gap;
  }

  &__title {
    color: $white;
    border-bottom: solid 1px rgba($white, 0.05);
    padding-bottom: 6px;
    margin-bottom: $gap;
    width: 100%;
  }
}

.row {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  gap: $gap;
  align-items: center;

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }
}

.fullwidth {
  grid-area: 1/1/1/3;
}
