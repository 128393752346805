@mixin mobile {
  @media screen and (max-width: #{($tablet - 1)}) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $tablet) {
    @content;
  }
}

@mixin tablet-large {
  @media screen and (min-width: $tablet-large) {
    @content;
  }
}

@mixin laptop {
  @media screen and (min-width: $laptop) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}
