@use "styles/shared" as *;

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  padding: 10px;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  border: none;
  border-radius: $borderRadius;
  cursor: pointer;
  transition-duration: 0.6s;
  gap: 4px;

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.default {
  color: $light;
  background-color: $default;

  &:hover {
    box-shadow: 0 0 5px 5px rgba($default, 0.1);
  }
}

.primary {
  color: $light;
  background-color: $primary;

  &:hover {
    box-shadow: 0 0 5px 5px rgba($primary, 0.1);
  }
}

.success {
  color: $light;
  background-color: $success;

  &:hover {
    box-shadow: 0 0 5px 5px rgba($success, 0.1);
  }
}

.warning {
  color: $light;
  background-color: $warning;

  &:hover {
    box-shadow: 0 0 5px 5px rgba($warning, 0.1);
  }
}

.accent {
  color: $black;
  background-color: $accent;

  &:hover {
    box-shadow: 0 0 5px 5px rgba($accent, 0.1);
  }
}

.link {
  color: $black;
  background-color: transparent;
  padding: 0;

  &:hover {
    color: $success;
  }
}

.customButtonPosition {
  position: absolute;
  top: 15%;
  right: 10%;
  @include mobile{
  
  top:13%
  }
}

.customButtonPosition_help {
  position: absolute;
  top: 18%;
  left: 2.5%;
  @include mobile{
    margin-bottom: 10px;
    position: initial;
    
  }
}
