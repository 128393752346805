@use "styles/shared" as *;

.link {
  color: $light;
  transition-duration: 0.5s;

  &:hover {
    color: $success;
  }
}

.active {
  a {
    color: $success;
    font-weight: 500;
  }
}
