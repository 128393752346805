@use "styles/shared" as *;

.sidebar {
  position: relative;
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;

  @include laptop {
    display: none;
  }

  > button {
    color: $white;
    font-size: 24px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba($black, 0.8);
  width: 100%;
  height: 100%;

  &__content {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.content {
  position: absolute;
  top: 0;
  right: 0;
  width: 80%;
  height: 100%;
  padding: 20px;
  background-color: $light;
  color: $black;
  box-shadow: 0 0 5px 5px rgba($primary, 0.2);

  display: flex;
  flex-direction: column;
  gap: $gap;
}

.hidden {
  position: fixed;
  top: 0;
  right: -100%;
  background-color: rgba($black, 0.8);
}

.nav {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 100%;

  &__user {
    a {
      display: flex;
      align-items: center;
      gap: $gap;
      color: $black;
      font-weight: 500;
    }

    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
      object-position: center center;
      border-radius: 50px;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: right;
    margin-top: 50px;

    > a {
      font-size: 18px;
      font-weight: 600;
      color: $shade;

      &:hover,
      &:focus,
      &:active {
        color: $primary;
      }
    }
  }

  &__logout {
    color: $warning;
    margin-top: 50px;
    gap: 8px;
  }
}
