@use "styles/shared" as *;

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: $gap;
  background-color: $shade;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 14px;
  margin-top: auto;
  text-align: center;
  color: $accent;

  a {
    color: $white;
  }
}
