@use "styles/shared" as *;

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: $gap;

  & > img {
    width: 100%;
    height: 100%;
    max-width: 70px;
    max-height: 70px;
    object-fit: contain;
    animation: pulse 2s ease-in-out infinite;
  }

  & > span {
    color: $white;
    width: fit-content;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}
