@use "styles/shared" as *;

.formRadio {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 4px;
}

.options {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $gap;
  background-color: $black;
  width: 100%;
  padding: 13px;
  border-radius: $borderRadius;
  border: solid 1px rgba($white, 0.2);
}

.radioGroup {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: fit-content;

  > span {
    margin: 0;
  }
}

.radioLabel {
  display: block;
  width: 100%;
  color: $white;

  > span {
    display: block;
    width: 100%;
    margin-bottom: $borderRadius;
  }
}
