@use "styles/shared" as *;

.textArea {
  background-color: $black;
  outline: none;
  border: solid 1px rgba($white, 0.2);
  border-radius: 6px;
  padding: 10px;
  width: 100%;
  color: $white;
  font-size: 20px;
  resize: none;

  &:focus,
  &:active,
  &:hover {
    border: solid 1px $white;
  }
}
