@use "styles/shared" as *;

.account {
  position: relative;
  width: fit-content;
  display: none;

  @include tablet {
    display: block;
    margin-left: auto;
    margin-right: $gap;
  }

  @include laptop {
    margin: initial;
  }

  &__menu {
    position: absolute;
    top: 100%;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: $gap;
    background-color: $light;
    color: $black;
    border-radius: $borderRadius;

    transition-duration: 0.4s;
    padding: 0;
    width: 0;
    height: 0;
    overflow: hidden;

    > button {
      margin-top: auto;
      color: $warning;
    }
  }

  &__user {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    align-items: center;
    justify-content: center;
    height: 100%;

    img,
    span,
    svg {
      pointer-events: none;
    }

    img {
      width: 30px;
      height: 30px;
      object-fit: contain;
      object-position: center center;
      border-radius: 30px;
      grid-column: span 2;
      margin: 0 auto;
    }

    span {
      color: $light;
      font-weight: 300;
    }

    svg {
      margin: 0 auto 5px auto;
      color: $light;
    }
  }

  &__profile {
    display: grid;
    grid-template-columns: 1fr 50px;
    grid-template-rows: auto;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    img {
      width: 30px;
      height: 30px;
      object-fit: contain;
      object-position: center center;
      border-radius: 30px;
      grid-row: span 2;
      margin: 0 auto;
    }

    strong {
      color: $shade;
    }

    span {
      color: $black;
      font-size: 14px;
      opacity: 0.7;
    }

    img,
    span {
      pointer-events: initial;
    }
  }

  &__category {
    display: flex;
    flex-direction: column;
    gap: 4px;

    > span {
      font-weight: 700;
    }

    > a {
      color: $shade;
      opacity: 0.7;

      &:hover {
        color: $success;
      }
    }
  }
}

.active {
  box-shadow: 0 0 15px 15px rgba($black, 0.5);
  padding: 20px;
  height: 470px;
  width: 270px;
  overflow: hidden;
}
