@use "styles/shared" as *;

.sidebar {
  display: flex;
  flex-direction: column;
  border-radius: $borderRadius;
  height: fit-content;
  gap: $gap;

  @include laptop {
    position: sticky;
    top: 80px;
  }
}

.profile {
  width: 100%;
  position: relative;
  border-radius: $borderRadius;
  background-color: $light;
  overflow: hidden;
  padding: 20px 0;

  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100px;
    object-fit: cover;
    object-position: center;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translate(-50%, 50%);
    object-fit: cover;
    object-position: center;
    border-radius: 100px;
  }

  &__about {
    width: 100%;
    padding: 140px 20px 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    color: $shade;

    > a {
      color: $shade;
      font-weight: 700;
      font-size: 18px;
    }

    > span {
      opacity: 0.7;
      font-size: 14px;
    }
  }
}

.aboutMe {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $gap;

  svg {
    font-size: 18px;
  }
}

.organization {
  width: 100%;
  height: 100px;
  position: relative;
  border-radius: $borderRadius;
  background-color: $light;
  overflow: hidden;
  background-color: #f1f5f6;

  &__cover {
    width: 100%;
    height: 100px;
    object-fit: cover;
    object-position: center;
  }

  &__image {
    position: absolute;
    top: 15px;
    left: 20px;
    width: 150px;
    height: auto;
    object-fit: contain;
    object-position: left;
  }
}
