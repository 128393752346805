@use "styles/shared" as *;

.reactSelect {
  width: 100%;
  padding: 3px 10px;
  outline: none;
  border-radius: 6px;
  border: solid 1px rgba($white, 0.2);
  background-color: $black;
  color: $white;
  font-size: 20px;

  > div {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    margin: 0;
    left: 0;
    cursor: pointer;

    > div:first-child {
      background-color: $black;
      padding: 0;

      > div {
        background-color: $black;
        color: $white;

        &[tabindex="-1"]:hover {
          background-color: $white;
          color: $black;
        }
      }
    }

    div[data-value] {
      background: transparent !important;
      margin-top: 0;
    }

    input {
      color: $white !important;
    }
  }
}
