@use "styles/shared" as *;

.header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  background-color: $shade;
  gap: $gap;
  z-index: 9;

  > a {
    height: fit-content;
    margin: auto auto auto 0;
  }

  &__logo {
    width: fit-content;
    margin: auto 0;

    img,
    source {
      max-width: 50px;
      max-height: 50px;
      object-fit: contain;

      @include tablet {
        max-width: 150px;
        max-height: 37px;
      }
    }
  }

  @include laptop {
    gap: 50px;
  }
}

.navigation {
  width: fit-content;
  height: 100%;
  display: none;

  @include laptop {
    display: block;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 300;
  }
}

.link {
  color: $warning;
  transition-duration: 0.5s;

  &:hover {
    color: $success;
  }
}
