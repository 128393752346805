@use "styles/shared" as *;

.input {
  background-color: $black;
  outline: none;
  border: solid 1px rgba($white, 0.2);
  border-radius: 6px;
  padding: 10px;
  width: 100%;
  color: $white;
  font-size: 20px;

  &[type="time"]::-webkit-calendar-picker-indicator,
  &[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
    cursor: pointer;
  }

  &:focus,
  &:active,
  &:hover {
    border: solid 1px $white;
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: no-drop;

    &:focus,
    &:active,
    &:hover {
      border: solid 1px rgba(transparent, 0);
    }
  }
}
