@use "styles/shared" as *;

.reactModal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  background-color: $shade;
  padding: 20px;
  color: $white;
  width: 100%;
  height: 100%;

  &__body {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__close {
    align-self: flex-end;

    > button {
      color: $white;
    }
  }

  @include mobile {
    overflow-x: auto;
  }

  @include tablet {
    max-width: 600px;
    height: auto;
    max-height: 100%;
    overflow-x: auto;
    border-radius: $borderRadius;
  }

  @include laptop {
    max-width: 800px;
  }
}
