@use "styles/shared" as *;

.notificationCard {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 19;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: $gap;
}
