@use "styles/shared" as *;

.box {
  width: 100%;
  height: fit-content;
  padding: 20px;
  border-radius: $borderRadius;
}

.default {
  background-color: $default;
}

.shade {
  background-color: $shade;
}

.light {
  background-color: $light;
  color: $black;
}

.accent {
  background-color: $accent;
  color: $black;
}
