@use "styles/shared" as *;

.formLabel {
  display: block;
  width: 100%;
  color: currentColor;
  cursor: pointer;

  > span {
    display: block;
    width: 100%;
    margin-bottom: 6px;
  }

  &__disabled {
    cursor: no-drop;
  }
}
