@use "styles/shared" as *;

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 350px;
  height: 100%;
  margin: 0 auto;

  &__form {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $gap;
    padding: 20px;
    background-color: $shade;
    color: $light;
    border-radius: $borderRadius;
  }

  &__logo {
    width: 200px;
    height: auto;
    object-fit: contain;
    margin-bottom: $gap;
  }

  &__button {
    width: 100%;
    margin-top: $gap;
  }

  &__container {
    width: 100%;
    display: flex;
    align-items: flex-start;

    a {
      color: $white;
      opacity: 0.6;
      transition-duration: 0.7s;

      &:hover {
        opacity: 1;
      }
    }
  }
}
