@use "styles/shared" as *;

.input {
  width: fit-content;

  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    line-height: 16px;
    width: fit-content;

    > span:first-child {
      width: fit-content;
      display: block;
      margin: 0;
      padding: 10px;
      background-color: $primary;
      color: $white;
      border-radius: $borderRadius;
    }

    > span:last-child {
      width: fit-content;
      margin: 0 0 0 10px;
    }
  }
}
